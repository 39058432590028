<template lang="pug">
.billing-pending
  h1.title.has-text-centered Processing payment...
  b-loading(:is-full-page="true" :can-cancel="false" :active.sync="isLoading")
  b-message(title="Error" type="is-warning" aria-close-label="Close message" v-if="errorMessage") {{ errorMessage }}
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'BillingPending',
  data: () => ({
    isLoading: true,
    errorMessage: null
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  watch: {
    user: {
      immediate: true,
      handler(userRecord) {
        if (userRecord.paymentState === 'paid') {
          this.isLoading = false
          this.$buefy.toast.open('Welcome!')
          this.$router.push('/portfolio/data').catch(() => {})
        }
        if (userRecord.paymentState === 'failed') {
          this.errorMessage =
            'Your payment failed. Please contact support@nutworth.com'
          this.isLoading = false
        }
      }
    }
  },
  metaInfo: {
    title: 'Payment Pending'
  }
}
</script>
<style lang="sass" scoped>
.billing-pending
  flex: 1 1 auto
  display: flex
  flex-direction: column
  padding-top: 4rem
  max-width: 600px
  margin: 0 auto
</style>
