<template lang="pug">
.card-field
  label(for="card-element") Credit or Debit Card Number
  #card-element
  #card-errors(role="alert" v-if="errorMessage") {{errorMessage}}
</template>

<script>
import stripe from '@/misc/stripe'

const STRIPE_STYLE = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}

export default {
  props: {
    value: Object
  },
  data: () => ({
    errorMessage: null
  }),
  mounted() {
    const elements = stripe().elements()
    this.card = elements.create('card', { style: STRIPE_STYLE })
    this.card.mount('#card-element')
    this.$emit('input', this.card)
    this.card.addEventListener('change', event => {
      if (event.error) {
        this.errorMessage = event.error.message
      } else {
        this.errorMessage = null
      }
      this.$emit('input', this.card)
    })
  },
  destroyed() {
    this.card.destroy()
  }
}
</script>

<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;

  margin-top: 0.5rem;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>

<style lang="sass" scoped>
label
  font-weight: bold
#card-errors
  color: red
  margin-top: 0.5rem
</style>
