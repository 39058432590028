<template lang="pug">
settings-shell
  template(#head)
    h1.title(v-if="!showUpgradeText") Choose Plan
    h1.title(v-if="showUpgradeText") Upgrade to Pro
  .box
    b-message(title="About our Pro Plans" type="is-primary" :closable="false" v-if="showUpgradeText")
      | To get the most out of nutworth and support its ongoing
      | improvement, consider switching to a pro plan. Our pro plans
      | do not limit the number of entries or categories that you
      | can use, allow you to export your data in any supported currency
      | and unlock additional sharing features.
    b-message(title="Payment Cancelled" type="is-info" :closable="false" v-if="showCancelText")
      | We're sorry that you did not go through with your purchase.
      | You can choose a more appropriate plan for your needs here.
      | If you'd like to go back to the payment page instead,&nbsp;
      router-link(to="/billing/init") simply click here.
    b-message(title="At Plan Limit" type="is-warning" :closable="false" v-if="showMustUpgradeText")
      | You've reached the maximum number of entries allowed on our starter plan.
      | Thanks for trying us out! If you want to upgrade to allow unlimited
      | entries, please choose a paid plan below.
    .plan-picker
      .plan-picker-title
        strong Choose your Plan
      plan-chooser(v-bind.sync="form")
    b-message(title="Warning" type="is-warning" :closable="false" v-if="isDowngrading")
      | You're switching from a paid plan to the free plan. Once you do this,
      | your account will be downgraded immediately. If your account membership renewed
      | recently, consider cancelling closer to your membership renewal date
      | to ensure that you maximise the value of your subscription. Thanks for your support and
      | if you have any feedback or queries, please don't hesitate to&nbsp;
      a(href="mailto:support@nutworth.com") contact us.
    b-button.is-primary(:disabled="user.plan == form.plan" :loading="isProcessing" @click="updatePlan") Save Plan
</template>

<script>
import { mapState, mapActions } from 'vuex'

import SettingsShell from '@/components/SettingsShell'
import PlanChooser from '@/components/PlanChooser'

export default {
  name: 'BillingPlan',
  components: {
    PlanChooser,
    SettingsShell
  },
  filters: {
    capitalize(s) {
      return s.toUpperCase()
    }
  },
  data: () => ({
    form: {
      plan: 'starter',
      currency: 'usd',
      coupon: null
    },
    isProcessing: false,
    showCancelText: false,
    showUpgradeText: false,
    showMustUpgradeText: false
  }),
  computed: {
    ...mapState('authentication', ['user']),
    currencyIdx() {
      if (this.form.currency === 'aud') return 1
      return 0
    },
    isDowngrading() {
      if (~['needed', 'failed'].indexOf(this.user.paymentState)) return false
      return this.user.plan !== 'starter' && this.form.plan === 'starter'
    }
  },
  watch: {
    '$route.query.action': {
      immediate: true,
      handler(action) {
        this.showCancelText = action === 'cancelled'
        this.showUpgradeText = action === 'upgrade'
        this.showMustUpgradeText = action === 'at_entry_limit'
      }
    },
    user: {
      immediate: true,
      deep: true,
      handler(user) {
        if (user) {
          this.form.plan = this.user.plan
          this.form.currency = this.user.billingCurrency
        }
      }
    }
  },
  methods: {
    ...mapActions('authentication', ['changePlan']),
    updatePlan() {
      this.isProcessing = true
      this.changePlan(this.form)
        .then(() => this.$router.push('/welcome'))
        .catch(error => {
          this.isProcessing = false
          console.error(error.message)
        })
    }
  },
  metaInfo: {
    title: 'Choose Plan'
  }
}
</script>

<style lang="sass" scoped>
.plan-picker
  padding-bottom: 1rem
.plan-picker-title
  padding-bottom: 0.5rem
.cancel-text
  margin-bottom: 1rem
.box
  max-width: 800px
</style>
