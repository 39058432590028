<template lang="pug">
.billing-init
  h1.title.has-text-centered Redirecting you to payment...
  b-loading(:is-full-page="true" :can-cancel="false" :active.sync="isLoading")
  b-message(title="Error" type="is-warning" aria-close-label="Close message" v-if="errorMessage") {{ errorMessage }}
</template>
<script>
import firebase from 'firebase/app'
import stripe from '@/misc/stripe'

export default {
  name: 'BillingInit',
  data: () => ({
    isLoading: true,
    errorMessage: null
  }),
  mounted() {
    firebase
      .functions()
      .httpsCallable('createCheckoutSession')()
      .then(response => {
        stripe().redirectToCheckout({ sessionId: response.data })
      })
      .catch(error => {
        console.error(error)
        this.isLoading = false
        this.errorMessage =
          'Could not redirect to checkout. Try again or contact support for assistance.'
      })
  },
  metaInfo: {
    title: 'Redirecting to Payment'
  }
}
</script>
<style lang="sass" scoped>
.billing-init
  flex: 1 1 auto
  display: flex
  flex-direction: column
  padding-top: 4rem
  max-width: 600px
  margin: 0 auto
</style>
