export default [
  {
    id: 'starter',
    name: 'Free',
    shortDesc: 'Get started tracking your net worth for free.',
    classes: 'is-dark',
    price: ['Free', 'Free'],
    lines: [
      'Up to 100 Entries',
      '8 Categories',
      'Multi-Currency Support',
      'Crypto Support',
      'XLSX Export in USD',
      'XLSX Import',
      '-',
      'Free for life'
    ],
    buttonText: 'Get started for free'
  },
  {
    id: 'monthly',
    name: 'Month to Month',
    shortDesc: 'Unlock all features and support development.',
    classes: 'is-twitter',
    price: ['3.50', '5'],
    currency: '$',
    period: 'month',
    lines: [
      'Unlimited Entries',
      'Unlimited Categories',
      'Multi-Currency Support',
      'Crypto Support',
      'XLSX Export',
      'XLSX Import',
      'Sharing Features',
      'A low amount, paid monthly'
    ],
    buttonText: 'Choose'
  },
  {
    id: 'annual',
    name: 'Annual',
    shortDesc: 'All features, saving 40% over monthly.',
    classes: ['is-primary', 'is-active'],
    price: ['25', '35'],
    currency: '$',
    period: 'year',
    lines: [
      'Unlimited Entries',
      'Unlimited Categories',
      'Multi-Currency Support',
      'Crypto Support',
      'XLSX Export',
      'XLSX Import',
      'Sharing Features',
      'Save 40% over monthly'
    ],
    buttonText: 'Choose'
  }
]
