/* global Stripe */
const STRIPE_KEY = process.env.VUE_APP_STRIPE_PK

let _stripe

export default function stripe() {
  if (_stripe) return _stripe
  _stripe = Stripe(STRIPE_KEY)
  return _stripe
}
