<template lang="pug">
.plan-chooser-widget
  .plans(v-if="isNewPlan")
    .plan(v-for="planInfo in NewPlans" :key="planInfo.id")
      .radio-box.box.is-paddingless(:class="{'is-chosen': plan == planInfo.id}")
        .pro-badge(v-if="planInfo.price[currencyIdx] != 'Free'") PRO
        b-radio(:value="plan" @input="updatePlan(planInfo)" name="plan" :native-value="planInfo.id")
          .plan-title
            span.plan-name {{planInfo.name}}
            | &nbsp;-&nbsp;
            span.plan-price
              | {{planInfo.currency}}{{planInfo.price[currencyIdx]}}
              span(v-if="planInfo.period") /{{planInfo.period}}
          .plan-details {{planInfo.shortDesc}}
  .plans(v-else)
    .plan(v-for="planInfo in plans" :key="planInfo.id")
      .radio-box.box.is-paddingless(:class="{'is-chosen': plan == planInfo.id}")
        .pro-badge(v-if="planInfo.price[currencyIdx] != 'Free'") PRO
        b-radio(:value="plan" @input="updatePlan(planInfo)" name="plan" :native-value="planInfo.id")
          .plan-title
            span.plan-name {{planInfo.name}}
            | &nbsp;-&nbsp;
            span.plan-price
              | {{planInfo.currency}}{{planInfo.price[currencyIdx]}}
              span(v-if="planInfo.period") /{{planInfo.period}}
          .plan-details {{planInfo.shortDesc}}
  a.coupon-toggle(v-if="showCouponToggle" @click="showCouponInput = true")
    | I have a coupon
  .coupon-picker(v-if="showCouponInput")
    strong Add Coupon
    .field.has-addons
      b-input(v-model="couponInput" :readonly="isCheckingCoupon" autofocus @keydown.native.enter="checkCoupon")
      p.control
        b-button.button.is-primary(@click="checkCoupon" :loading="isCheckingCoupon") Apply
    strong.coupon-error(v-if="couponBad")
      | Sorry, that's not a valid coupon code.
    strong.coupon-description(v-if="couponDescription")
      | {{couponDescription}} Discount will be applied at checkout.
</template>

<script>
import { mapState } from 'vuex'

import { findIndex } from 'lodash'
import PLANS from '@/misc/plan-info'
import NewPlans from '@/misc/plan-info-updated'
import firestore from '@/firebase/async-firestore'

const CURRENCIES = ['usd', 'aud']

export default {
  props: {
    plan: String,
    currency: String,
    coupon: String
  },
  data() {
    return {
      showCouponInput: false,
      couponInput: '',
      isCheckingCoupon: false,
      couponBad: false,
      couponDescription: null,
      PLANS,
      NewPlans,
      CURRENCIES
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    currencyIdx() {
      return CURRENCIES.indexOf(this.currency)
    },
    showCouponToggle() {
      if (this.showCouponInput) {
        return false
      }
      if (this.plan === 'starter') {
        return false
      }
      if (!this.user) {
        return true
      }
      if (this.user.plan !== this.plan) {
        return true
      }
      if (this.user.paymentState === 'needed') {
        return true
      }
      return false
    },
    canUpdateCurrency() {
      if (!this.user) {
        return true
      }
      if (!this.user.stripeId) {
        return true
      }
      // Customers in stripe are locked to their initial currency
      return false
    },
    isNewPlan() {
      return this.user.isNewPlan
    },
    plans() {
      const currentPlan = PLANS.find(plan => plan.id === this.user.plan)
      const plans = [...NewPlans]
      const index = findIndex(plans, ({ id }) => id === currentPlan.id)
      if (~index) {
        plans[index] = currentPlan
      }
      return plans
    }
  },
  methods: {
    updatePlan({ id }) {
      this.$emit('update:plan', id)
      if (id === 'starter') {
        this.showCouponInput = false
      }
    },
    updateCurrency(currency) {
      this.$emit('update:currency', currency)
    },
    async checkCoupon() {
      this.couponBad = false
      this.couponDescription = null
      this.$emit('update:coupon', null)
      if (this.couponInput === '') {
        return
      }
      this.isCheckingCoupon = true
      const db = await firestore()
      const doc = await db
        .collection('coupons')
        .doc(this.couponInput.toLocaleLowerCase())
        .get()
      if (!doc.exists || doc.data().expired) {
        this.couponBad = true
      } else {
        this.$emit('update:coupon', doc.id)
        this.couponDescription = doc.data().description
      }
      this.isCheckingCoupon = false
    }
  }
}
</script>

<style lang="sass" scoped>
.plans
  display: flex
  flex-wrap: wrap
  margin: -0.5rem
  align-items: stretch
  margin-bottom: 1rem
.plan
  width: 50%
  min-width: 320px
  flex-grow: 1
  padding: 0.5rem
  display: flex
  align-items: stretch
.plan .box
  width: 100%
.plan-name
  font-weight: bold
.plan-details
  font-size: 0.9rem
  color: #777
.is-chosen
  border: 1px solid #43ae43
.b-radio
  padding: 1rem
  width: 100%
.currency-note
  font-size: 0.9rem
.payment-currency
  display: flex
.radio-box
  position: relative
.pro-badge
  position: absolute
  top: -1px
  right: -1px
  background: #43ae43
  color: white
  font-weight: bold
  padding: 0.15rem 1rem
  border: 1px solid darken(#43ae43, 10)
  border-top-right-radius: 4px
  font-size: 0.7rem
.coupon-picker
  margin-bottom: 0.5rem
  strong
    display: block
    margin-bottom: 0.5rem
.coupon-toggle
  position: relative
  top: -0.5rem
.coupon-error
  color: red
.coupon-description
  color: green
</style>
