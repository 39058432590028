<template lang="pug">
settings-shell
  template(#head)
    h1.title Update Payment Method
  .constrained-width(v-if="user.paymentState === 'failed'")
    b-message(title="Your Subscription Payment Failed" type="is-warning" :closable="false")
      | Please update your payment details
      | below to continue using nutworth. If you are unable to make payment,
      | your account will be automatically changed to our free starter plan
      | soon. Alternatively, you can&nbsp;
      router-link(to="/billing/choose_plan") click here to change your plan now.
  .box.constrained-width
    b-field(label="Name")
      b-input(v-model="info.name")
    card-field(v-model="card")
    b-field(label="Billing Address")
      b-input(v-model="info.address_line1")
    b-field(label="Billing Address (continued)")
      b-input(v-model="info.address_line2")
    b-field(label="City")
      b-input(v-model="info.address_city")
    b-field(label="Postcode/ZIP")
      b-input(v-model="info.address_zip")
    b-field(label="Country")
      .control.has-icons-left
        .select
          country-select(v-model="info.address_country" :country="info.address_country" topCountry="US")
        b-icon.is-left(size='is-small' icon='globe-asia' type='is-grey')
    b-field(label="State")
      .control.has-icons-left
        .select
          region-select(v-model="info.address_state" :country="info.address_country" :region="info.address_state")
        b-icon.is-left(size='is-small' icon='map-marker-alt' type='is-grey')
    .buttons
      b-button.is-primary(@click="tokenizeAndUpdate" :loading="submitting") Update
</template>

<script>
import firebase from 'firebase/app'
import { CountrySelect, RegionSelect } from 'vue-country-region-select'
import { mapState, mapActions } from 'vuex'
import CardField from '@/components/CardField'
import SettingsShell from '@/components/SettingsShell'
import stripe from '@/misc/stripe'

export default {
  name: 'BillingUpdate',
  components: { CardField, CountrySelect, RegionSelect, SettingsShell },
  data: () => ({
    submitting: false,
    card: null,
    info: {
      name: '',
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_state: '',
      address_zip: '',
      address_country: ''
    }
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  methods: {
    ...mapActions('authentication', ['markPending']),
    tokenizeAndUpdate() {
      this.submitting = true
      stripe()
        .createToken(this.card, this.info)
        .then(result => {
          if (result.error) {
            this.submitting = false
            this.$buefy.dialog.alert({
              title: 'An error occurred',
              message: result.error.message
            })
          } else {
            this.updatePaymentMethod(result.token)
          }
        })
    },
    updatePaymentMethod(card) {
      firebase
        .functions()
        .httpsCallable('updatePaymentDetails')({ card: card.id })
        .then(() => {
          if (this.user.paymentState === 'failed') {
            this.markPending()
          }
          this.$buefy.toast.open('Updated successfully.')
          this.$router.push('/welcome').catch(() => {})
          this.submitting = false
        })
        .catch(error => {
          this.$buefy.dialog.alert({
            title: 'An error occurred',
            message: error.message
          })
          this.submitting = false
        })
    }
  },
  metaInfo: {
    title: 'Update Payment Method'
  }
}
</script>

<style lang="sass" scoped>
.constrained-width
  max-width: 800px
.box
  padding: 1rem
.card-field
  margin-bottom: 1rem
  max-width: 500px
</style>
